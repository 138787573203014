/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { animated as a, useSpring } from "react-spring"

import { useEditMode } from "../../context/EditContext"
import AudioButton from "../AudioButton"

export default ({ audio, special, base, translation, language }) => {
  const { editMode, toggleEditModal } = useEditMode()
  const audioValue = special ? translation.characters : translation.value

  const audioShift = useSpring({
    opacity: audio ? 1 : 0,
    visibility: audio ? "visible" : "hidden",
    width: audio ? 65 : 0,
  })

  return (
    <div
      className="edit-phrase"
      onClick={() =>
        editMode ? toggleEditModal("edit", "phrase", base, translation) : null
      }
      sx={{
        p: 20,
        borderBottom: "1px solid #dcdcdc",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div sx={{ flex: 1 }}>
        <p sx={{ fontWeight: 600, mb: "5px" }}>{base}</p>
        {special ? (
          <Fragment>
            <p sx={{ fontSize: 20, mt: 10 }}>{translation.characters}</p>
            <p sx={{ fontSize: "15px", color: "#7d7d7d" }}>
              {translation.romanization}
            </p>
          </Fragment>
        ) : (
          <p>{translation.value}</p>
        )}
      </div>
      <a.div
        sx={{
          willChange: "opacity",
          display: "flex",
          button: {
            height: 42,
            width: 42,
            borderRadius: 2,
            border: "1px solid #dcdcdc",
            "&:hover, &:active, &:focus": { bg: "#f9f9f9" },
          },
        }}
        style={audioShift}
      >
        <AudioButton inline={false} string={audioValue} language={language} />
      </a.div>
    </div>
  )
}
