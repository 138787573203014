/** @jsx jsx */
import { jsx } from "theme-ui"

import BasicCell from "./BasicCell"
import PhraseCell from "./PhraseCell"
import language from "../../config/language"

export default ({ data, audio }) => {
  return data.map(section => {
    // Basics Section

    if (section.category === "Basics") {
      return (
        <div
          key="basics"
          className="basic-table edit-theme"
          sx={{
            bg: "#f9f9f9",
            border: "1px solid #dcdcdc",
            p: 20,
            opacity: 0,
            animation: "fadeInUp 0.8s ease forwards .3s",
            mb: 40,
          }}
        >
          <ul sx={{ columns: [1, 2], columnGap: 40 }}>
            {section.rows.map(row => (
              <BasicCell
                key={row.base}
                audio={audio}
                base={row.base}
                translation={row.translation}
                special={language.special}
                language={language.id}
              />
            ))}
          </ul>
        </div>
      )
    }

    // Default Section

    return (
      <div
        key={section.category}
        id={section.category.replace(/\s+/g, "-").toLowerCase()}
        className="phrase-table edit-theme"
        sx={{
          mb: [0, 40],
          opacity: 0,
          animation: "fadeInUp 0.8s ease forwards .3s",
          border: "1px solid #dcdcdc",
          "> div:last-of-type": { border: "none" },
        }}
      >
        <h2
          className="edit-heading"
          sx={{
            zIndex: 1,
            boxShadow: ["1px 1px 1px #dcdcdc", "none"],
            color: "primary",
            fontSize: [22, 28],
            bg: "#f9f9f9",
            borderBottom: ["none", "1px solid #dcdcdc"],
            p: "12px 20px",
            textAlign: "left",
            position: ["sticky", "relative"],
            top: 0,
          }}
        >
          {section.category}
        </h2>
        {section.rows.map(row => (
          <PhraseCell
            key={row.base}
            audio={audio}
            base={row.base}
            translation={row.translation}
            special={language.special}
            language={language.id}
          />
        ))}
      </div>
    )
  })
}
