/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { animated as a, useSpring } from "react-spring"

import { useEditMode } from "../../context/EditContext"
import AudioButton from "../AudioButton"

export default ({ audio, base, translation, special, language }) => {
  const { editMode, toggleEditModal } = useEditMode()
  const audioValue = special ? translation.characters : translation.value

  const audioShift = useSpring({
    opacity: audio ? 1 : 0,
    visibility: audio ? "visible" : "hidden",
    width: audio ? 65 : 0,
  })

  return (
    <li
      className="edit-phrase"
      onClick={() =>
        editMode ? toggleEditModal("edit", "phrase", base, translation) : null
      }
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 15,
      }}
    >
      <div sx={{ fontWeight: 600, width: "46%" }}>{base}</div>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flex: 1,
          button: {
            width: 42,
            height: 42,
            bg: "#fff",
            borderRadius: 2,
            border: "1px solid #dcdcdc",
          },
        }}
      >
        {special ? (
          <Fragment>
            <div>{translation.characters}</div>
            <div>{translation.romanization}</div>
          </Fragment>
        ) : (
          <div>{translation.value}</div>
        )}
        <a.div style={audioShift} sx={{ willChange: "opacity" }}>
          <AudioButton inline={false} string={audioValue} language={language} />
        </a.div>
      </div>
    </li>
  )
}
