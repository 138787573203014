/** @jsx jsx */
import { jsx } from "theme-ui"
import Helmet from "react-helmet"
import { Fragment, useState, useEffect } from "react"
import { ContentWrapper, Main, Sidebar } from "gatsby-theme-elements"
import { Global } from "@emotion/core"
import styled from "@emotion/styled"

import { useFlashCards } from "../../context/FlashContext"
import { useEditMode } from "../../context/EditContext"

import AddButton from "../EditMode/AddButton"
import PhraseTable from "./PhraseTable"
import PostHeader from "../PostHeader"
import PostToolbar from "../PostToolbar"
import SocialShare from "../SocialShare"
import SEO from "../Seo"
import AdBox from "../Ad"

export default ({ pageContext }) => {
  const { updateCards } = useFlashCards()
  const { editMode } = useEditMode()
  const [audio, toggleAudio] = useState(true)
  const title = pageContext.table.title

  useEffect(() => {
    updateCards(pageContext.data)
  }, [pageContext.data])

  return (
    <Fragment>
      <SEO title={`${title} Phrases`} />
      {/* <Global
        styles={{
          html: {
            scrollBehavior: "smooth",
          },
        }}
      /> */}

      {editMode ? (
        <Helmet>
          <body className="edit-mode" />
        </Helmet>
      ) : null}

      <ContentWrapper layout="content-sidebar" sx={{ p: [0, "70px 20px"] }}>
        <Main>
          <div sx={{ variant: editMode ? "editMode" : null }}>
            <PostHeader postType="phrasebook" title={pageContext.table.title} />
            <div
              sx={{
                opacity: 0,
                zIndex: 1,
                position: "relative",
                animation: "fadeInUp 0.5s ease forwards .3s",
              }}
            >
              <SocialShare title={title} postType="Phrasebook" size={50} />
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  p: ["0 20px 30px", "10px 0 40px"],
                }}
              >
                <PostToolbar audio={audio} toggleAudio={toggleAudio} />
                {editMode ? <AddButton label="Add a Phrase" /> : null}
              </div>
            </div>
            <PhraseTable
              data={pageContext.data}
              audio={audio}
              editMode={editMode}
            />
          </div>
        </Main>
        <Sidebar>
          <div
            sx={{
              variant: "sideMenu",
              opacity: 0,
              animation: "fadeInUp .4s ease forwards .2s",
            }}
          >
            <h2>Topics</h2>
            <ul>
              {pageContext.data.map(section =>
                section.category !== "Basics" ? (
                  <li key={section.category}>
                    <a
                      href={`#${section.category
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                    >
                      {section.category}
                    </a>
                  </li>
                ) : null
              )}
            </ul>
          </div>
          <AdContainer>
            <AdBox type="small" />
            <AdBox type="large" sticky />
          </AdContainer>
        </Sidebar>
      </ContentWrapper>
    </Fragment>
  )
}

const AdContainer = styled.div`
  opacity: 0;
  animation: fadeInUp ease 0.5s forwards 0.6s;
`
